<template>
  <div class="container">
    <h4 class="text-center my-4">Расписание выбора ИУП</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">
      <Button icon="pi pi-plus" label="Добавить элемент" class="p-button-rounded"
              @click="addElement()"/>

      <div v-for="(s, sIndex) in chooseDisciplineCalendar_form.chooseDisciplineCalendar" :key="sIndex"
           class="border p-3 my-2">
        <div class="remove-item__button d-flex flex-row-reverse">
          <i class="fa fa-times" @click="deleteElement(s)"></i>
        </div>

        <div class="row mt-2">
          <div class="col-md-3"><b>Календарь</b></div>
          <div class="col-md-4"><b>Курс {{ s.study_course }} ({{ s.study_level }})</b></div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Начало выбора расписания</div>
          <div class="col-md-4">
            <input type="datetime-local" class="form-control" placeholder="Начало"
                   :value="s.start_date"
                   @change="changeDate(sIndex, 'start_date', $event)">
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3">Конец выбора расписания</div>
          <div class="col-md-4">
            <input type="datetime-local" class="form-control" placeholder="Конец"
                   :value="s.end_date"
                   @change="changeDate(sIndex, 'end_date', $event)">
          </div>
        </div>

        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Календарь</label>
          <div class="col-md-4">
            <select @change="changeSelected($event, sIndex)" class="form-control form-select">
              <option
                  v-for="(option, index) in [{id:0, study_level_id: '', study_course: null, name: 'Выберите'},...educationCalendar]"
                  :value="option.id" :key="index"
                  :selected="s.education_calendar_id === option.id">
                {{ option.study_level }} {{ option.study_course }} {{ option.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center" v-if="chooseDisciplineCalendar_form.chooseDisciplineCalendar.length > 0">
        <Button icon="pi pi-save" label="Сохранить" class="my-4" @click="saveCalendar"/>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapMutations, mapState} from 'vuex'

export default {
  name: 'ChooseDisciplineCalendar',
  data() {
    return {
      loading: true,
      educationCalendar: [],
      form: null,
      chooseDisciplineCalendar: []
    }
  },
  computed: {
    ...mapState('chooseDisciplineCalendar', ['chooseDisciplineCalendar_form']),
  },
  methods: {
    ...mapActions('chooseDisciplineCalendar', ['GET_EDUCATION_CALENDAR', 'GET_CHOOSE_DISCIPLINE_CALENDAR', 'POST_CHOOSE_DISCIPLINE_CALENDAR', 'PUT', 'DELETE']),
    ...mapMutations('chooseDisciplineCalendar', ['ADD_ELEMENT', 'DELETE_ELEMENT', 'SET_DATE', 'CHANGE_SELECTED']),

    addElement() {
      this.ADD_ELEMENT()
    },

    async deleteElement(s) {
      console.log(s, 'delete element')
      if (s.id != null) {
        await this.DELETE(s.id)
        await this.GET_CHOOSE_DISCIPLINE_CALENDAR()
      } else {
        this.DELETE_ELEMENT(s)
      }
    },

    async saveCalendar() {
      this.loadingSave = true

      if (this.chooseDisciplineCalendar_form.chooseDisciplineCalendar.filter(i => !i.id).length > 0) {
        const postRes = await this.POST_CHOOSE_DISCIPLINE_CALENDAR()
        if (postRes) {
          await this.GET_CHOOSE_DISCIPLINE_CALENDAR()
          this.$message({title: 'Успешно сохранено'})
        }
      } else {
        const putRes = await this.PUT()
        if (putRes) {
          await this.GET_CHOOSE_DISCIPLINE_CALENDAR()
          this.$message({title: 'Успешно обновлено'})
        }
      }

      this.loadingSave = false
    },

    changeSelected(e, sIndex) {
      this.CHANGE_SELECTED({value: e.target.value, sIndex})
    },

    changeDate(sIndex, property, e) {
      let date = e.target.value
      this.SET_DATE({sIndex, property, date})
    },

  },
  async mounted() {
    this.educationCalendar = await this.GET_EDUCATION_CALENDAR()
    await this.GET_CHOOSE_DISCIPLINE_CALENDAR()
    this.loading = false
  }
}

</script>
<style scoped>
</style>
